import { isNotBlank, ScrollToTop, WebVitalsWidget } from '@segunosoftware/equinox';
import '@segunosoftware/equinox/dist/styles.css';
import * as Sentry from '@sentry/react';
import '@shopify/polaris/build/esm/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import APIProvider from './APIProvider';
import App from './App';

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
	Sentry.init({
		release: isNotBlank(__SEGUNO_APP_VERSION__) ? `mailchimp-connector-app@${__SEGUNO_APP_VERSION__}` : undefined,
		dsn: 'https://e913f172f1db4545a21601c17ced57b5@o155661.ingest.sentry.io/1444965',
		integrations: defaults => [...defaults, Sentry.browserTracingIntegration()],
		tracesSampleRate: 0.2,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
	});
}
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<StrictMode>
		<APIProvider baseUrl={apiEndpoint}>
			<QueryClientProvider client={queryClient}>
				<Router>
					<ScrollToTop>
						<App />
					</ScrollToTop>
					<WebVitalsWidget enabled={import.meta.env.VITE_WEB_VITALS_WIDGET_ENABLED === '1'} location="sw" />
				</Router>
			</QueryClientProvider>
		</APIProvider>
	</StrictMode>
);
